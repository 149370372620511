import React from "react";

const NotFound = () => (
	<div className="content">
		<div className="section py-5" id="soumya">
			<br></br>
			<h1 className="heading top-heading">There isn't a page here.</h1>
			<h4 className="heading subsection-heading">Are you sure you've got the right <strong className="color-one-only">address?</strong></h4>
			<br></br>
			<p className="main-content mt-0">
				Looks like you're lost in the void. Try heading back <a href="/"><strong className="heading subsection-heading">home!<span role="img" aria-label="laptop">🏡</span></strong></a>
			</p>
			<br></br>
			<br></br>
			<br></br>
			<br></br>
			<br></br>
			<br></br>
			<br></br>
			<br></br>
		</div>
	</div>

);

export default NotFound;