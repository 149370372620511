import React from 'react';

function Opensource(props) {
	return (
		<div className="custom-card custom-os-card">
			<div>
				<h5 className="heading subsection-heading" style={{ textTransform: "capitalize" }}>
					{props.title}
				</h5>
				<p className="main-content">{props.content}&nbsp;</p>
			</div>
			<div>
				<span className="ml-1 pt-3 pb-3"><a href={props.url} target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt"></i></a></span>
				<br></br>
			</div>
		</div>
	);
}

export default Opensource;