import React from 'react';

function Preloader(props) {
	return (
		<div className="loader">
			<div className="binding"></div>
			<div className="pad">
				<div className="line line1"></div>
				<div className="line line2"></div>
				<div className="line line3"></div>
			</div>
			<div className="text">
				<p className="heading subsection-heading">COMPILING...</p>
			</div>
		</div>
	);
}

export default Preloader;